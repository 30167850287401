// import react libs
import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'

// import css libs
import styled, { createGlobalStyle } from 'styled-components'
import media from 'styled-media-query'

// import layout components
//import Header from './Header'
//import Footer from './Footer'

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }
`

// styled SiteLayout element
const SiteLayout = styled.div`

  ${media.greaterThan("small")`
    /* screen width is greater than 450px (small) */

  `}

  ${media.greaterThan("medium")`
    /* screen width is greater than 768px (large) */

  `}
`

// main Layout Component
const Layout = ({ children }) => (
  <StaticQuery
    query={SiteQuery}
    render={data => (
      <SiteLayout>
        <GlobalStyle />
        {/*<Header siteTitle={data.site.siteMetadata.title} />*/}
        {children}
        {/*<Footer siteTitle={data.site.siteMetadata.title} />*/}
      </SiteLayout>
    )}
  />
)

// propTypes for Layout
Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

// query for site information
const SiteQuery = graphql`
query SiteTitleQuery {
  site {
    siteMetadata {
      title
    }
  }
}
`

// export Layout component
export default Layout
