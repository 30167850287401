// import react libs
import React from 'react'
//import PropTypes from 'prop-types'

// import css libs
import styled from 'styled-components'
import media from 'styled-media-query'
import Background from '../assets/highlight.jpg'

// import other components
import Line from './Line'

// styled Highlight element
const Highlight = styled.div`

  background-image: url(${Background});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  ${media.greaterThan("small")`
    /* screen width is greater than 450px (small) */

  `}

  ${media.greaterThan("medium")`
    /* screen width is greater than 768px (large) */

  `}
`

// Quote element
const Quote = styled.div`
  max-width: 60vw;
  text-align: center;
  border: 9px solid rgba(255,255,255,1);
  border-radius: 3px;
  color: #fff;
  margin: 0 auto;

  h2 {
    margin: 0;
    padding: 2rem;
    font-size: 1.5rem;
    font-size: 2.5rem;
    font-weight: 500;
    color: rgba(255,255,255,1);
  }
`

// Highlight component
export default () => (
  <Highlight>
    <Line
      bgColor="rgba(255,255,255,1)"
      height="10vh" />
    <Quote>
      <h2>Recent Work</h2>
    </Quote>
    <Line
      bgColor="rgba(255,255,255,1)"
      height="10vh" />
  </Highlight>
)
