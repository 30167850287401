// import react libs
import React from 'react'

// import css libs
import styled from 'styled-components'
import media from 'styled-media-query'
import Background from '../assets/chemex.jpg'

// import other components
import Line from './Line'
import SocialMedia from './SocialMedia'

// styled Wrapper element
const BgWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  background: #010101;
`

// styled PageHeader element
const PageHeader = styled.header`

  width: 100vw;
  height: 100vh;
  background-color: #010101;
  background-image: url(${Background});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  color: #fff;

  display: flex;
  justify-content: center;
  align-items: center;

  animation: fadein 700ms;

  ${media.greaterThan("small")`
    /* screen width is greater than 450px (small) */

  `}

  ${media.greaterThan("medium")`
    /* screen width is greater than 768px (large) */

  `}

  @keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
  }


`

// styled Content element
const Content = styled.div`
  /* position: absolute;
  bottom: 0;
  right: 0;
  left: 0; */
`

// styled Logo element
const Logo = styled.h1`
  color: #fff;
  font-size: 1.5rem;
  width: 4rem;
  line-height: 4rem;
  background: rgba(255,255,255,0.1);
  text-align: center;
  margin: 35vh 0 0;
  margin: 0 auto;
  font-family: 'Avenir Next', sans-serif;
  animation: fadein 1s;

  ${media.greaterThan("small")`
    /* screen width is greater than 450px (small) */
    font-size: 2rem;
    width: 6rem;
    line-height: 6rem;
  `}

  ${media.greaterThan("medium")`
    /* screen width is greater than 768px (large) */

  `}
`

// styled Intro element
const Intro = styled.div`
  font-weight: 100;
  font-size: 1.25rem;
  text-align: center;
  margin: 2rem 0;
  animation: fadein 1.5s;
  padding: 0 1rem;

  strong {
    font-weight: 400;
  }

  ${media.greaterThan("small")`
    /* screen width is greater than 450px (small) */
    font-size: 1.65rem;
    width: 30rem;
    margin: 1rem auto;
  `}

  ${media.greaterThan("medium")`
    /* screen width is greater than 768px (large) */
    width: 36rem;
  `}
`

// styled Dot element
const Dot = styled.div `
  width: 2rem;
  height: 2rem;
  border-radius: 2rem;
  background: rgba(255,255,255,0.1);
  margin: 0 auto;
`

// Masthead component
const Masthead = () => (
  <BgWrapper>
    <PageHeader>
      <Content>
        <Logo>KH</Logo>
        <Intro>
          <strong>Hi, I'm Kaleb.</strong> UI, UX, visual media designer,
          and mentor helping you tell your story online.
        </Intro>
        <SocialMedia />
        {/*<Dot />
        <Line
          bgColor="rgba(255,255,255,0.1)"
          height="30vh" />*/}
      </Content>
    </PageHeader>
  </BgWrapper>
)

// export Masthead component
export default Masthead
