// import react libs
import React from 'react'
//import PropTypes from 'prop-types'

// import css libs
import styled from 'styled-components'
import media from 'styled-media-query'

// colors
const headerBg = "#279ae6"
const headerColor = "#fff"

// styled Boxes element
const Boxes = styled.div`

  margin: 0 2rem;

  ul {
    position: relative;
    max-width: 1100px;
    margin: 0 auto 0;
    list-style: none;
    padding: 0;
  }

  ul:before {
    content: "";
    position: absolute;
    left: 0;
    margin-left: -.25rem;
    height: 100%;
    width: .5rem;
    background: #d0d0d0;

    ${media.greaterThan("small")`
      /* screen width is greater than 450px (small) */
      left: 50%;
    `}
  }

  ul li {
    display: list-item;

    &:last-child {
      margin-bottom: 0;
    }
  }

  ul li:before {
    content: '';
    position: absolute;
    left: 0;
    height: 2rem;
    width: 2rem;
    border-radius: 50%;
    margin-top: 3rem;
    margin-left: -1rem;
    background: #3a3a3c;

    ${media.greaterThan("small")`
      /* screen width is greater than 450px (small) */
      left: 50%;
    `}
  }

  ul li:after {
    display: table;
    content: "";
    clear: both;
  }

  ul li:nth-child(odd) div {
    float: right;

    ${media.greaterThan("small")`
      /* screen width is greater than 450px (small) */
      float: left;
    `}
  }

  ul li:nth-child(even) div {
    float: right;
  }

  ul li:nth-child(odd) div:before {
    left: 45%;
    border-right-color: transparent;
    border-left: 1rem solid #f3f3f5;
  }

  ul li:nth-child(even) div:before {
    right: 45%;
    left: auto;
  }

  .content {
    border-radius: 4px;
    background: #f3f3f5;
    margin: 2rem 0;
    padding: 2rem;
    width: 90%;
    text-align: center;

    div {
      font-size: 1.25rem;
      line-height: 1.4;
    }

    ${media.greaterThan("small")`
      /* screen width is greater than 450px (small) */
      width: 45%;
    `}
  }

  .content:before {
    content: '';
    position: absolute;
    left: 10%;
    margin-top: -1rem;
    margin-left: -1rem;
    width: 0;
    height: 0;
    border-top: 1rem solid transparent;
    border-bottom: 1rem solid transparent;
    border-right: 1rem solid #f3f3f5;

    ${media.greaterThan("small")`
      /* screen width is greater than 450px (small) */
      margin-left: 0;
    `}
  }



  /* header colors */
  ul li:nth-child(odd) div.header:before {
    left: 45%;
    border-right-color: transparent;
    border-left: 1rem solid ${headerBg};
  }
  .content.header {
    background: ${headerBg};
    color: ${headerColor};
  }
  .content.header:before {
    border-right: 1rem solid ${headerBg};
  }


  h3 {
    line-height: 1.1;
    font-size: 1.6rem;
    font-weight: 500;
    margin-bottom: 1rem;
  }

  p {
    font-size: 1.1rem;
    line-height: 1.4;
  }

  a {
    color: ${headerBg};
    text-decoration: none;
  }

  ${media.greaterThan("small")`
    /* screen width is greater than 450px (small) */
    h3, p {
      text-align: center;
    }
  `}

`

// Boxes component
export default ({ boxes, flip }) => (
  <Boxes>
    <ul>

      {flip && ( // flip the starting position of boxes from left to right
        <li style={{ display: "none" }}></li>
      )}

      {boxes.map(box => (
        <li key={box.title}>
          <div className={`content ${box.style}`}>
            <h3>{box.title}</h3>
            {box.htmlSafe && (
              box.content
            )}
            {!box.htmlSafe && (
              <div
                className="description"
                dangerouslySetInnerHTML={{ __html: box.content }} />
            )}
          </div>
        </li>
      ))}

    </ul>
  </Boxes>
)
