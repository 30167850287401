// import react libs
import React from 'react'
import PropTypes from 'prop-types'

// import css libs
import styled from 'styled-components'
import media from 'styled-media-query'

// styled Line element
const LineEl = styled.div`

  width: 9px;
  height: ${props => props.height ? props.height : '50vh' };
  background: ${props => props.bgColor ? props.bgColor : '#d0d0d0' };
  margin: 0 auto;

  ${media.greaterThan("small")`
    /* screen width is greater than 450px (small) */

  `}

  ${media.greaterThan("medium")`
    /* screen width is greater than 768px (large) */

  `}
`

// Line component
const Line = ({ height, bgColor }) => (
  <LineEl
    height={height}
    bgColor={bgColor} />
)

// Line propTypes
Line.propTypes = {
  height: PropTypes.string,
  bgColor: PropTypes.string,
}

// Line default propTypes
Line.defaultProps = {
  height: '50vh',
  bgColor: '#d0d0d0'
}

// export Line component
export default Line
