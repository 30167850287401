// import react libs
import React from 'react'
//import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'

// import other components
import Line from './Line'
import BoxList from './BoxList'

// Services component
const Services = () => (
  <StaticQuery
    query={ServicesQuery}
    render={data => {

      let boxes = data.services.edges[0].node.frontmatter.boxes
      let flip = data.services.edges[0].node.frontmatter.flip

      return (
        <>
          <Line height="10vh" />
          <BoxList boxes={boxes} flip={flip} />
          <Line height="10vh" />
        </>
      )
    }}
  />
)

// Services propTypes
Services.propTypes = {
  // bgColor: PropTypes.string,
}

// Services default propTypes
Services.defaultProps = {
  // bgColor: '#d0d0d0'
}


// query for site information
const ServicesQuery = graphql`
query ServicesQuery {
  services:allMarkdownRemark(
    filter:{
      frontmatter: {
        title: { eq: "Services" }
      }
    }
  ) {
    edges {
      node {
        id
        frontmatter {
          title
          boxes {
            content
            title
            published
            style
          }
        }
      }
    }
  }
}
`

// export Services component
export default Services
