// import react libs
import React from 'react'

// import components
import Layout from '../components/Layout/Layout'
import SEO from '../components/Layout/Seo'
import Masthead from '../components/Masthead'
import Work from '../components/Work'
import Highlight from '../components/Highlight'
import Services from '../components/Services'
//import Contact from '../components/Contact'

// Home page component
const IndexPage = () => (
  <Layout>
    <SEO title="Home" keywords={['web', 'development', 'design']} />
    <Masthead />
    {/*<Services />
    <Highlight />
    <Work />
    {/*<Contact />*/}
  </Layout>
)

export default IndexPage
