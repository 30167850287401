// import react libs
import React from 'react'

// import css libs
import styled from 'styled-components'

// import components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  //faFacebookF,
  faTwitter,
  faMedium } from '@fortawesome/free-brands-svg-icons';

// styled SocialMedia element
const SocialMedia = styled.nav`

  position: absolute;
  bottom: 2rem;
  right: 2rem;

  @keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    text-align: center;

    animation: fadein 3s;

    li {
      display: inline-block;
      margin: 0 0.25rem;

      a {
        background: rgba(255,255,255,0.2);
        font-size: 1rem;
        line-height: 2.25rem;
        width: 2.25rem;
        border-radius: 2.25rem;
        display: block;
        text-align: center;
        color: #fff;
        transform: translateZ(0);
        transition: all 0.3s ease;
        position: relative;

        &:before {
          content: "";
          position: absolute;
          z-index: -1;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: rgba(255,255,255,0.4);
          transform: scaleX(0);
          transform-origin: 0 50%;
          transition-property: transform;
          transition: 300ms ease-out;
          border-radius: 2.25rem;

        }
        &:hover:before {
          transform: scaleX(1);
        }
      }
    }
  }

`

// List of social links
const SocialLinks = [
  // {
  //   title: 'Facebook',
  //   url: 'https://facebook.com/kalebheitzman',
  //   icon: faFacebookF
  // },
  {
    title: 'Twitter',
    url: 'https://twitter.com/kalebheitzman',
    icon: faTwitter
  },
  {
    title: 'Medium',
    url: 'https://medium.com/khdesign',
    icon: faMedium
  },
]

// SocialMedia component
export default () => (
  <SocialMedia>
    <ul>
    {SocialLinks.map((link,idx) => (
      <li key={idx}>
        <a href={link.url} title={link.title}>
          <FontAwesomeIcon icon={link.icon} />
        </a>
      </li>
    ))}
    </ul>
  </SocialMedia>
)
