// import react libs
import React from 'react'
//import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'

// import other components
import Line from './Line'
import BoxList from './BoxList'

// Work component
const Work = () => (
  <StaticQuery
    query={WorkQuery}
    render={data => {

      let boxes = data.work.edges[0].node.frontmatter.boxes
      let flip = data.work.edges[0].node.frontmatter.flip

      return (
        <>
          <Line height="10vh" />
          <BoxList boxes={boxes} flip={flip} />
          <Line height="10vh" />
        </>
      )
    }}
  />
)

// Work propTypes
Work.propTypes = {
  // bgColor: PropTypes.string,
}

// Work default propTypes
Work.defaultProps = {
  // bgColor: '#d0d0d0'
}


// query for site information
const WorkQuery = graphql`
query WorkQuery {
  work:allMarkdownRemark(
    filter:{
      frontmatter: {
        title: { eq: "Work" }
      }
    }
  ) {
    edges {
      node {
        id
        frontmatter {
          title
          flip
          boxes {
            content
            title
            published
            style
          }
        }
      }
    }
  }
}

`

// export Work component
export default Work
